import {BigNumber} from '@ethersproject/bignumber';
import {BaseProvider} from '@ethersproject/providers';
import {useQuery} from 'react-query';
import CommentSpacer from './CommentSpacer';
import MenuFrame from './MenuFrame';
import PinkLink from './PinkLink';
import {useWalletProvider} from './useWeb3';

export default function AccountInfo({address, provider}: {address: string; provider: BaseProvider}) {
  const {disconnect} = useWalletProvider();
  const {data} = useQuery(['account', address], () => fetchAccount(address, provider));

  if (!data) {
    return <MenuFrame>{shortenAddress(address)}</MenuFrame>;
  }

  return (
    <MenuFrame>
      <span style={{cursor: 'pointer'}} onClick={disconnect}>
        {shortenAddress(data.name || address)}
      </span>
    </MenuFrame>
  );
}

export function shortenAddress(address: string) {
  if (address.startsWith('0x') && address.length > 16) {
    address = address.substring(0, 6) + '…' + address.substr(address.length - 4);
  }
  return address;
}

function formatBalance(balance: BigNumber) {
  return (
    balance.div('1000000000000000000').toString() +
    '.' +
    balance.mod('1000000000000000000').toString().padStart(18, '0').substring(0, 4)
  );
}

type AccountInfo = {
  name: string | null;
  balance: BigNumber;
};

async function fetchAccount(address: string, provider: BaseProvider): Promise<AccountInfo> {
  const [name, balance] = await Promise.all([provider.lookupAddress(address), provider.getBalance(address)]);

  return {name, balance};
}
