import Head from 'next/head';
import collection from '../lib/geomes/collection';
import ButtonLink from '../components/ButtonLink';
import PinkLink from '../components/PinkLink';
import {useChainId, useConnect, useEthereum, useProvider, useWalletAddress} from '../components/hooks';
import MenuFrame from '../components/MenuFrame';
import AccountInfo from '../components/AccountInfo';
import CommentSpacer from '../components/CommentSpacer';
import collections from '../lib/collections';
import {useWalletProvider} from './useWeb3';

type Props = {
  title: string;
  description?: string;
  image?: string;
};

export default function PageHeader({title, image, description}: Props) {
  // const ethereum = useEthereum();
  // const address = useWalletAddress(ethereum);
  // const chainId = useChainId(ethereum);
  // const provider = useProvider(ethereum, chainId);
  // const connect = useConnect(ethereum);
  const {web3Provider, address, connect} = useWalletProvider();

  description =
    description ||
    'Code Makes Art is a generative art platform bringing new and expressive art to the Ethereum blockchain.';
  image = image || collections[1].baseURL + collection.image;
  if (!image.startsWith('http')) {
    image = collections[1].baseURL + image;
  }

  return (
    <header>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={collection.baseURL} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={collection.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:description" content={description} />
      </Head>
      <MenuFrame>
        <PinkLink href="/">mint</PinkLink>
        <CommentSpacer />
        <PinkLink href="/quasars">quasars</PinkLink>
        <CommentSpacer />
        <PinkLink href="/geomes">geomes</PinkLink>
        <CommentSpacer />
        <PinkLink href="/about">about</PinkLink>
      </MenuFrame>
      {address && web3Provider ? (
        <AccountInfo address={address} provider={web3Provider} />
      ) : (
        <ButtonLink onClick={connect} text="Connect" />
      )}
      <style jsx>{`
        header {
          display: flex;
          justify-content: space-between;
        }
        @media (max-width: 600px) {
          header {
            flex-direction: column-reverse;
          }
          header > :global(*) {
            margin-bottom: 12px;
          }
        }
      `}</style>
    </header>
  );
}
