export default function CommentSpacer({text = '///'}: {text?: string}) {
  return (
    <span className="spacer">
      {text}
      <style jsx>{`
        .spacer {
          margin: 0px 12px 2px;
          color: var(--comment-color);
          font-size: 14px;
          line-height: 150%;
        }
      `}</style>
    </span>
  );
}
