export default function ButtonLink({onClick, disabled, border, bgcolor, text, small}: any) {
  return (
    <button className="container" onClick={onClick} disabled={disabled}>
      {text}
      <style jsx>{`
        .container {
          border: solid ${border || '1'}px var(--border-color);
          padding: ${small ? '8px 12px 8px' : '18px 19px 16px'};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;
          transition: box-shadow 150ms;
          transition-timing-function: ease-in-out;
          outline: none;
          background-color: ${bgcolor || 'var(--background-color)'};
        }
        .container:not([disabled]):hover {
          cursor: pointer;
          background-color: var(--hover-color);
          box-shadow: inset 0px -3px 0px 0px var(--accent-color);
        }
        .container:not([disabled]):active {
          padding: ${small ? '9px 12px 7px' : '19px 19px 15px'};
          transition: box-shadow 10ms;
          box-shadow: inset 0px -2px 0px 0px var(--accent-color);
        }
      `}</style>
    </button>
  );
}
