export default function MenuFrame(props: any) {
  return (
    <div className="menu-frame">
      {props.children}
      <style jsx>{`
        .menu-frame {
          border: solid 1px var(--border-color);
          padding: 17px 19px;
          font-size: 17px;
          line-height: 21px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .menu-frame > * {
          display: flex;
        }
      `}</style>
    </div>
  );
}
