import Link from 'next/link';

export default function PinkLink({href, children, inverse, ...props}: any) {
  const external = href.startsWith('/') ? null : {target: '_blank', rel: 'noopener'};
  return (
    <>
      <Link passHref href={href}>
        <a {...props} {...external}>
          {children}
        </a>
      </Link>
      <style jsx>{`
        :global(p) a {
          border-bottom: solid 1px var(--accent-color);
        }
        a:link,
        a:visited {
          color: var(${inverse ? '--foreground-reverse' : '--foreground-color'});
          text-decoration: none;
        }
        a:hover {
          color: var(--accent-color);
        }
      `}</style>
    </>
  );
}
